import React from "react";

import "./components/HeroContactImgStyles.css";

import Navbar from "./components/Navbar";
import HeroContactImg from "./components/HeroContactImg";

const ContactUs = () => {
  return (
    <div>
      <Navbar />
      <HeroContactImg />

      <iframe
        title="Google Maps"
        width="100%"
        min-height="100%"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28867.24205223818!2d51.45225238372016!3d25.256949367563088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45da86992f25b1%3A0x773dedb6b35cf273!2sArab%20United%20Construction%20W.L.L.%20(Arcon)!5e0!3m2!1sen!2sqa!4v1679219934649!5m2!1sen!2sqa"
        allowFullScreen
      />
      <p className="footerContactBtm">
        © 2023 Arcon Facility Management W.L.L. All rights reserved.
      </p>
    </div>
  );
};

export default ContactUs;
