import React from "react";

import "./HomeStyles.css";

import Navbar from "./components/Navbar";
import HeroImg from "./components/HeroImg";
import Footer from "./components/Footer";

const Home = () => {
  return (
    <div id="Home">
      <Navbar />
      <HeroImg />
      <Footer />
    </div>
  );
};

export default Home;
