import "./NavbarStyles.css";

import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import LogoImg from "../assets/ArconLogo.png";

import { Link } from "react-router-dom";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg" : "header"}>
      <div className="header-container">
        <Link to="/">
          <div className="logo">
            <img className="logo-img" src={LogoImg} alt="LogoImg" />
          </div>
        </Link>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="/About">About</Link>
          </li>

          <li>
            <Link to="/Services">Services</Link>
          </li>

          <li>
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes className="menuBTNclose" />
          ) : (
            <FaBars className="menuBTNopen" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
