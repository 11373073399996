import React from "react";

import "./ServicesStyles.css";

import Navbar from "./components/Navbar";
import HeroImgAll from "./components/HeroImgAll";
import Footer from "./components/Footer";

import HardServ from "./assets/hardServ.jpg";
import SoftServ from "./assets/softServ.jpg";
import ManagementServ from "./assets/managementServ.jpg";
import SpecialServ from "./assets/specialServ.jpg";

import electrical from "./assets/electrical.jpg";
import hvac from "./assets/hvac.jpg";
import plum from "./assets/plum.jpg";
import civil from "./assets/civil.jpg";

import clean from "./assets/clean.jpg";
import house from "./assets/house.jpg";
import pest from "./assets/pest.jpg";
import reception from "./assets/reception.jpg";
import security from "./assets/security.jpg";
import parking from "./assets/parking.jpg";
import land from "./assets/land.jpg";
import waste from "./assets/waste.jpg";

import contract from "./assets/contract.jpg";
import software from "./assets/software.jpg";
import estate from "./assets/estate.jpg";
import build from "./assets/build.jpg";
import audit from "./assets/audit.jpg";
import defect from "./assets/defect.jpg";
import space from "./assets/space.jpg";

import fire from "./assets/fire.jpg";
import system from "./assets/system.jpg";
import elevators from "./assets/elevators.jpg";
import light from "./assets/light.jpg";
import generators from "./assets/generators.jpg";
import gas from "./assets/gas.jpg";
import access from "./assets/access.jpg";
import pool from "./assets/pool.jpg";

const OurServices = () => {
  return (
    <div>
      <Navbar />
      <HeroImgAll />

      <div className="content">
        <div className="topTitleServices">
          <h1>Services</h1>
        </div>

        <div className="servCol">
          <div className="servCol1">
            <img className="HardServ" src={HardServ} alt="Hard Services" />
            <div className="servTitle">
              <div className="titleCol">
                <h1>Hard Services</h1>
              </div>
            </div>
          </div>

          <div className="servCol2">
            <div className="servCol3">
              <img className="electrical" src={electrical} alt="Electrical" />
              <p>Electrical</p>
            </div>
            <div className="servCol3">
              <img className="hvac" src={hvac} alt="HVAC and chilled water" />
              <p>HVAC and chilled water</p>
            </div>
            <div className="servCol3">
              <img className="plum" src={plum} alt="Plumbing" />
              <p>Plumbing</p>
            </div>
            <div className="servCol3">
              <img className="civil" src={civil} alt="Civil works" />
              <p>Civil works</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>

        <div className="servCol">
          <div className="servCol1">
            <img className="SoftServ" src={SoftServ} alt="Soft Services" />
            <div className="servTitle">
              <div className="titleCol">
                <h1>Soft Services</h1>
              </div>
            </div>
          </div>

          <div className="servCol2">
            <div className="servCol3">
              <img className="clean" src={clean} alt="Cleaning" />
              <p>Cleaning</p>
            </div>
            <div className="servCol3">
              <img className="house" src={house} alt="Housekeeping" />
              <p>Housekeeping</p>
            </div>
            <div className="servCol3">
              <img className="pest" src={pest} alt="Pest control & hygiene" />
              <p>Pest control & hygiene</p>
            </div>
            <div className="servCol3">
              <img className="reception" src={reception} alt="Reception" />
              <p>Receptions</p>
            </div>
            <div className="servCol3">
              <img className="security" src={security} alt="Security" />
              <p>Security</p>
            </div>
            <div className="servCol3">
              <img className="parking" src={parking} alt="Parking management" />
              <p>Parking management</p>
            </div>
            <div className="servCol3">
              <img className="land" src={land} alt="Landscaping" />
              <p>Landscaping</p>
            </div>
            <div className="servCol3">
              <img
                className="waste"
                src={waste}
                alt="Waste management services"
              />
              <p>Waste management services</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>

        <div className="servCol">
          <div className="servCol1">
            <img
              className="managementServ"
              src={ManagementServ}
              alt="managementServ"
            />
            <div className="servTitle">
              <div className="titleCol">
                <h1>Management Services</h1>
              </div>
            </div>
          </div>

          <div className="servCol2">
            <div className="servCol3">
              <img className="contract" src={contract} alt="Contracts" />
              <p>Contracts</p>
            </div>
            <div className="servCol3">
              <img
                className="software"
                src={software}
                alt="Software integration"
              />
              <p>Software integration</p>
            </div>
            <div className="servCol3">
              <img className="estate" src={estate} alt="Real estate" />
              <p>Real estate</p>
            </div>
            <div className="servCol3">
              <img className="build" src={build} alt="Building handover" />
              <p>Building handover</p>
            </div>
            <div className="servCol3">
              <img className="audit" src={audit} alt="Conditional audit" />
              <p>Conditional audit</p>
            </div>
            <div className="servCol3">
              <img className="defect" src={defect} alt="Defect liability" />
              <p>Defect liability</p>
            </div>
            <div className="servCol3">
              <img className="space" src={space} alt="Space management" />
              <p>Space management</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>

        <div className="servCol">
          <div className="servCol1">
            <img className="specialServ" src={SpecialServ} alt="specialServ" />
            <div className="servTitle">
              <div className="titleCol">
                <h1>Special Services</h1>
              </div>
            </div>
          </div>

          <div className="servCol2">
            <div className="servCol3">
              <img
                className="fire"
                src={fire}
                alt="Fire fighting & fire protection"
              />
              <p>Fire fighting & fire protection</p>
            </div>
            <div className="servCol3">
              <img
                className="system"
                src={system}
                alt="Building management systems"
              />
              <p>Building management systems</p>
            </div>
            <div className="servCol3">
              <img
                className="elevators"
                src={elevators}
                alt="Elevators & escalators"
              />
              <p>Elevators & escalators</p>
            </div>
            <div className="servCol3">
              <img className="light" src={light} alt="Lighting control" />
              <p>Lighting control</p>
            </div>
            <div className="servCol3">
              <img className="generators" src={generators} alt="Generators" />
              <p>Generators</p>
            </div>
            <div className="servCol3">
              <img className="gas" src={gas} alt="Gas & fuel systems" />
              <p>Gas & fuel systems</p>
            </div>
            <div className="servCol3">
              <img
                className="access"
                src={access}
                alt="Access control system & CCTV"
              />
              <p>Access control system & CCTV</p>
            </div>
            <div className="servCol3">
              <img className="pool" src={pool} alt="Swimming pools" />
              <p>Swimming pools</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurServices;
