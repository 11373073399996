import "./FooterStyles.css";

import React from "react";
import {
  FaHome,
  FaPhone,
  FaFax,
  FaMailBulk,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const linkedinUrl = "https://www.linkedin.com/company/arconfm";

  return (
    <div id="ContactUs" className="footer">
      <div className="footer-container">
        <div className="info">
          <div className="location">
            <FaHome
              size={18}
              style={{
                color: "green",
                marginRight: "10px",
              }}
            />
            <div>
              <a href="https://goo.gl/maps/SSSnPqu8mCo15CJy9" target="_blank">
                <p>
                  G1, Ma’arid Al Waab, Salwa Road, P.O. Box: 22087, Doha, Qatar
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="info">
          <div className="phone">
            <FaPhone
              size={18}
              style={{
                color: "green",
                marginRight: "10px",
              }}
            />
            <div>
              <a href="tel:+974 4469 6902">
                <p>+974 4469 6902</p>
              </a>
            </div>
          </div>

          <div className="fax">
            <FaFax
              size={18}
              style={{
                color: "green",
                marginRight: "10px",
              }}
            />
            <div>
              <a href="tel:+974 4469 6901 ;type=fax">
                <p>+974 4469 6901</p>
              </a>
            </div>
          </div>

          <div className="email">
            <FaMailBulk
              size={18}
              style={{
                color: "green",
                marginRight: "10px",
              }}
            />
            <div>
              <a href="mailto:info@arconfm.com">
                <p>info@arconfm.com</p>
              </a>
            </div>
          </div>
        </div>

        <div className="abtFooter">
          <p>
            <b>Arcon Facility Management (AFM):</b> a subsidiary of a
            family-owned group of companies, specializes in providing complete
            integrated facility management services in Doha, Qatar.
          </p>
          <br></br>
          <p>
            Our group of companies has been in Qatar since 1992, diversifying in
            contracting, MEP, BIM, fire systems, electromechanical,
            manufacturing, fit out, interior design, lighting, and trading.
          </p>
          <div className="social">
            <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
              <FaLinkedin
                size={20}
                style={{
                  color: "green",
                  marginRight: "10px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
      <p className="footerBtm">
        © 2023 Arcon Facility Management W.L.L. All rights reserved.
      </p>
    </div>
  );
};
export default Footer;
