import React from "react";

import "./ProjectsStyles.css";

import Navbar from "./components/Navbar";
import HeroImgAll from "./components/HeroImgAll";
import Footer from "./components/Footer";

const Projects = () => {
  return (
    <div>
      <Navbar />
      <HeroImgAll />
      <br></br>

      <div className="content">
        <div className="topTitleaAbout">
          <h1>Projects</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Projects;
