import "./HeroContactImgStyles.css";

import React from "react";
import { FaHome } from "react-icons/fa";

import dohadark from "../assets/dohadark.jpg";

const HeroContactImg = () => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="intro-img" src={dohadark} alt="IntroImg" />
      </div>
      <div className="contactTitle">
        <p>Contact Us</p>
        <br></br>
        <div className="btnContent">
          <a href="mailto:info@arconfm.com" className="btn btn1">
            Email us: info@arconfm.com
          </a>
          <a href="tel:+974 4469 6902" className="btn btn2 btn-light">
            Call Us: +974 4469 6902
          </a>
        </div>
        <br></br>
        <div className="addlocaCont">
          <FaHome
            size={25}
            style={{
              color: "green",
              marginRight: "5px",
            }}
          />
          <div className="addrCont">
            <a href="https://goo.gl/maps/SSSnPqu8mCo15CJy9" target="_blank">
              <p>
                G1, Ma’arid Al Waab, Salwa Road, P.O. Box: 22087, Doha, Qatar
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContactImg;
