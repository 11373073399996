import React from "react";

import Navbar from "./components/Navbar";
import HeroImgAll from "./components/HeroImgAll";
import Footer from "./components/Footer";

import "./AboutStyles.css";

import abtimg from "./assets/abtmus.jpg";
import teamWork from "./assets/TeamWork.png";
import portfoliopro from "./assets/portfoliopro.png";
import constraproc from "./assets/constraproc.png";
import diamond from "./assets/diamond.png";
import missvis from "./assets/missvis.jpg";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <HeroImgAll />
      <br></br>

      <div id="AboutUs" className="content">
        <div className="topTitleaAbout">
          <h1>About</h1>
        </div>

        <div className="abt1">
          <div className="abt1Col1">
            <h1>What we do</h1>
            <p>
              ARCON Facility Management (AFM) is a subsidiary of a group of
              companies that specializes in providing complete integrated
              facility management services in Qatar.
            </p>
          </div>

          <div className="abt1Col2">
            <h1>Why choose us</h1>
            <p>
              Our group of companies have been in the market for the last 30
              years, conducting integrated services in areas of engineering,
              procurement, construction, commissioning, operation, and
              maintenance.
            </p>
          </div>
        </div>

        <div className="title">
          <h3>Committed to delivering the highest standards of the industry</h3>
        </div>

        <div className="abt2">
          <div className="abt2Col1">
            <div className="row1">
              <img className="abtLogo" src={teamWork} alt="teamWork" />
              <p>
                Our team is our core strength and the skills of our workforce
                set us apart. We focus on continuous training and improvement.
              </p>
            </div>
            <div className="row1">
              <img className="abtLogo" src={portfoliopro} alt="portfoliopro" />
              <p>
                Our portfolio of projects includes major high-rise buildings and
                complex developments. This highlights the depth of experience we
                have and our commitment to the highest standards of quality.
              </p>
            </div>
            <div className="row1">
              <img className="abtLogo" src={constraproc} alt="constraproc" />
              <p>
                Our understanding of the construction process allows us to offer
                efficient and effective turnkey facility management solutions to
                our valued clients, at competitive prices.
              </p>
            </div>
            <div className="row1">
              <img className="abtLogo" src={diamond} alt="diamond" />
              <p>
                We understand the importance of consistency in managing
                properties and we are committed to building a long-term
                relationship with you.
              </p>
            </div>
          </div>

          <div className="abt2Col2">
            <div className="abtmask">
              <img className="abtimg" src={abtimg} alt="abtimg" />
            </div>
          </div>
        </div>

        <div className="abt3">
          <div className="abt3Col1">
            <div className="row1">
              <h1>Mission</h1>
              <p>
                ARCON has maintained its stable growth and is confident of its
                resourceful and cash flow capabilities in the years to come,
                sharing the vision of Qatar to be the premiere and world-class
                leader not only in economy but also as a diversified society by
                providing high standard of living.
              </p>
              <br></br>
              <p>
                ARCON aims toward the aptness of its efforts to share Qatar’s
                vision and aid the state in rendering the highest Standard in
                its construction projects. To safely deliver Any construction
                project on time, within budget, and with high-quality standards
                while respecting the local physical and social environment.
              </p>
            </div>

            <div className="row1">
              <h1>Vision</h1>
              <p>
                To seek excellence in revolutionizing the construction industry
                by achieving new standards of Integrated Management systems and
                productivity that are significantly above current industry
                standards.
              </p>
            </div>
          </div>

          <div className="abt3Col2">
            <div className="abtmask">
              <img className="abtimg" src={missvis} alt="missvis" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
