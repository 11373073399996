import "./HeroImgStyles.css";
import { Link } from "react-router-dom";

import React from "react";
import IntroImg from "../assets/intro-bg.jpg";

const HeroImg = () => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="intro-img" src={IntroImg} alt="IntroImg" />
      </div>

      <div className="contentTitle">
        <p>Welcome to Arcon</p>
        <div className="btnHome">
          <a className="btn">
            <Link to="/About">About Us</Link>
          </a>
          <a className="btn btn-light">
            <Link to="/Contact">Contact Us</Link>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;
