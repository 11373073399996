import "./HeroImgAllStyles.css";

import React from "react";
import IntroImgAll from "../assets/topbannerArcon.png";

const HeroImgAll = () => {
  return (
    <div className="heroAll">
      <div className="maskAll">
        <img className="introAll-img" src={IntroImgAll} alt="IntroImgAl" />
      </div>
    </div>
  );
};

export default HeroImgAll;
